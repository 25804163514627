<script setup  lang="ts">
const route = useRoute();
const localeRoute = useLocaleRoute();
</script>

<template>
    <header class="px-container-gap pt-5 md:pt-7 print:px-4">
        <div class="flex items-center justify-between">
            <NuxtLink :to="localeRoute({ name: 'esav', params: { bookingId: route.params.bookingId } })">
                <Logo />
            </NuxtLink>
        </div>
    </header>
</template>
